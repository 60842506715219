const { languageToCountryCode } = require('../shared/utils');
const { paths, routes } = require('../shared/routing');
const { getPath } = require('../shared/utils');

const fromToHP = [
    '/press',

    '/byznys-old',
    '/business-old',

    '/romania',

    '/business',
    '/CZ/business',
    '/SK/business',
    '/ES/business',
    '/IT/business',

    '/negocios',
    '/CZ/negocios',
    '/SK/negocios',
    '/ES/negocios',
    '/IT/negocios',

    '/byznys',
    '/CZ/byznys',
    '/SK/byznys',
    '/ES/byznys',
    '/IT/byznys',

    '/obchodnik',
    '/CZ/obchodnik',
    '/SK/obchodnik',
    '/ES/obchodnik',
    '/IT/obchodnik',

    '/obchodni-zaloha',
    '/CZ/obchodni-zaloha',
    '/SK/obchodni-zaloha',
    '/ES/obchodni-zaloha',
    '/IT/obchodni-zaloha',

    '/merchant-advance',
    '/CZ/merchant-advance',
    '/SK/merchant-advance',
    '/ES/merchant-advance',
    '/IT/merchant-advance',

    '/adelanto-de-ingresos',
    '/CZ/adelanto-de-ingresos',
    '/SK/adelanto-de-ingresos',
    '/ES/adelanto-de-ingresos',
    '/IT/adelanto-de-ingresos',

    '/credito-commerciante',
    '/CZ/credito-commerciante',
    '/SK/credito-commerciante',
    '/ES/credito-commerciante',
    '/IT/credito-commerciante',

    '/business/coronavirus',
    '/byznys/koronavirus',

    '/business/products',
    '/byznys/sluzby',

    '/byznys/sluzby/aplikace',
    '/business/gateway/app',

    '/business/gateway/link',
    '/byznys/sluzby/link',

    '/byznys/sluzby/vstupenky',
    '/business/gateway/ticketing',

    '/byznys/ucetnictvi',
    '/business/bookkeeping',

    '/reshoper',
    '/eet',
    '/malapokladna',
    '/fast-and-safe',
    '/club-mate',
    '/rychlovka',


    '/byznys/registrace/dekujeme',
    '/business/register/thanks',
    '/byznys/sluzby',
    '/business/products',
    '/byznys/sluzby/aplikace',
    '/business/gateway/app',
    '/byznys/sluzby/link',
    '/business/gateway/link',
    '/byznys/sluzby/vstupenky',
    '/business/gateway/ticketing',
    '/byznys/sluzby/shoptet',
    '/business/gateway/shoptet',
    '/byznys/sluzby/prestashop',
    '/business/gateway/prestashop',
    '/byznys/sluzby/api',
    '/business/gateway/api',

    // cashback
    '/cashback',
    '/ES/cashback',
    '/IT/cashback',
    '/CZ/cashback',
    '/SK/cashback',

    '/vernost-zakazniku',
    '/ES/vernost-zakazniku',
    '/IT/vernost-zakazniku',
    '/CZ/vernost-zakazniku',
    '/SK/vernost-zakazniku',

    '/customer-loyalty',
    '/ES/customer-loyalty',
    '/IT/customer-loyalty',
    '/CZ/customer-loyalty',
    '/SK/customer-loyalty',

    '/programas-de-fidelizacion',
    '/ES/programas-de-fidelizacion',
    '/IT/programas-de-fidelizacion',
    '/CZ/programas-de-fidelizacion',
    '/SK/programas-de-fidelizacion',

    '/fedelta-del-cliente',
    '/ES/fedelta-del-cliente',
    '/IT/fedelta-del-cliente',
    '/CZ/fedelta-del-cliente',
    '/SK/fedelta-del-cliente',

    '/merchant-apps',
    '/CZ/merchant-apps',
    '/IT/merchant-apps',
    '/SK/merchant-apps',
    '/ES/merchant-apps',

    '/aplikace-business',
    '/CZ/aplikace-business',
    '/IT/aplikace-business',
    '/ES/aplikace-business',
    '/SK/aplikace-business',

    '/apps-negocios',
    '/CZ/apps-negocios',
    '/IT/apps-negocios',
    '/ES/apps-negocios',
    '/SK/apps-negocios',

    '/apps-comerciante',
    '/CZ/apps-comerciante',
    '/IT/apps-comerciante',
    '/ES/apps-comerciante',
    '/SK/apps-comerciante',

    '/accept-payments',
    '/CZ/accept-payments',
    '/IT/accept-payments',
    '/ES/accept-payments',
    '/SK/accept-payments',

    '/akceptovat-platby',
    '/CZ/akceptovat-platby',
    '/IT/akceptovat-platby',
    '/ES/akceptovat-platby',
    '/SK/akceptovat-platby',

    '/acepta-pagos',
    '/CZ/acepta-pagos',
    '/IT/acepta-pagos',
    '/ES/acepta-pagos',
    '/SK/acepta-pagos',

    '/acceta-pagamenti',
    '/CZ/acceta-pagamenti',
    '/IT/acceta-pagamenti',
    '/ES/acceta-pagamenti',
    '/SK/acceta-pagamenti',

    '/merchant-dashboard',
    '/CZ/merchant-dashboard',
    '/IT/merchant-dashboard',
    '/ES/merchant-dashboard',
    '/SK/merchant-dashboard',

    '/prehled-plateb',
    '/CZ/prehled-plateb',
    '/IT/prehled-plateb',
    '/ES/prehled-plateb',
    '/SK/prehled-plateb',

    '/panel-de-control',
    '/CZ/panel-de-control',
    '/IT/panel-de-control',
    '/ES/panel-de-control',
    '/SK/panel-de-control',

    '/pannello-di-controllo',
    '/CZ/pannello-di-controllo',
    '/IT/pannello-di-controllo',
    '/ES/pannello-di-controllo',
    '/SK/pannello-di-controllo',

    // jobs
    '/jobs',
    '/prace',
    '/careers',
    '/volna-mista',
    '/carreras',
    '/carriere',
    '/carrieres',
    '/karrieren',
    '/carreiras',

    '/careers/senior-developer.tsx',
    '/volna-mista/senior-developer.tsx',

    '/careers/developer.js',
    '/volna-mista/developer.js',

    '/careers/designer.fig',
    '/volna-mista/designer.fig',

    '/careers/developer.kt',
    '/volna-mista/developer.kt',

    '/careers/growth-launcher-france',
    '/volna-mista/growth-launcher-france',

    '/careers/growth-launcher-germany',
    '/volna-mista/growth-launcher-germany',

    '/careers/growth-launcher-spain',
    '/volna-mista/growth-launcher-spain',

    '/careers/growth-launcher-italy',
    '/volna-mista/growth-launcher-italy',

    '/careers/not-listed',
    '/volna-mista/jsi-to-ty',
];

const fromToPersonal= [
    '/platba/nastaveni',
    '/payment/setup',
    '/platba/nova-karta',
    '/payment/new-card',
    '/platba/karta-pridana',
    '/payment/ready-to-pay',
]

const countryURLWhitelist = [
    '/prace',
    '/jobs',
]

const permanentRedirects = [
    { from: '/byznys/cenik', to: '/pricing' },
    { from: '/business/price-list', to: '/pricing' },
    { from: '/uzivatel', to: '/personal' },
    { from: '/user', to: '/personal' },
    { from: '/business/register', to: '/join-us' },
    { from: '/byznys/registrace', to: '/join-us' },

    { from: '/cenik', to: '/cs/CZ/cenik' },
    { from: '/price-list', to: '/en/CZ/pricing' },

    { from: '/byznys/faq', to: '/cs/faq' },
    { from: '/business/faq', to: '/en/faq' },
    { from: '/casto-kladene-otazky', to: '/cs/faq/uzivatel' },

    { from: '/byznys/koronavirus', to: '/cs/CZ/obchodnik' },
    { from: '/business/coronavirus', to: '/en/CZ/business' },

    { from: '/download', to: '/en/CZ/download' },
    { from: '/stahnout', to: '/cs/CZ/stahnout' },

    { from: '/terminy', to: '/it/termini' },
    { from: '/terminos', to: '/es/terminos' },
    { from: '/terms-and-conditions', to: '/en/CZ/terms' },
    { from: '/obchodni-podminky', to: '/cs/CZ/podminky' },

    { from: '/marketingove-podklady', to: '/cs/o-nas' },
    { from: '/tiskove-zpravy', to: '/cs/o-nas' },
    { from: '/press', to: '/en/about' },

    { from: '/byznys/registrace/dekujeme', to: '/cs/CZ/obchodnik' },

    { from: '/tiskove-zpravy/20-03-2020-coronavirus-pomoc', to: 'https://d36t5l3v2oc8ti.cloudfront.net/trisbee.com/images/og_image/press/20-03-2020-corona-help/document.pdf' },
    { from: '/press/20-03-2020-covid-help', to: 'https://d36t5l3v2oc8ti.cloudfront.net/trisbee.com/images/og_image/press/20-03-2020-corona-help/document.pdf' },

    { from: '/byznys', to: '/cs/CZ/obchodnik' },
    {
        from: '/italia', lang: {
            en: '/en/IT/business',
            it: '/it/IT/business',
            default: '/en/IT/business',
        },
    },

    {
        from: '/gdpr', lang: {
            en: '/en/terms/gdpr',
            cs: '/cs/podminky/gdpr',
            it: '/it/termini/gdpr',
            es: '/es/terminos/gdpr',
            default: '/en/ES/business',
        },
    },

    { from: '/podminky/uzivatel', to: '/cs/CZ/podminky/uzivatel' },
    { from: '/terms/users', to: '/en/CZ/terms/users' },

    { from: '/download/leaflet', to: '/stahnout/letak' },
    { from: '/download/leaflet', to: '/stahnout/letak' },
    { from: '/stahnout/triko', to: '/stahnout/letak' },
    { from: '/download/t-shirt', to: '/stahnout/letak' },
    { from: '/stahnout/rollup', to: '/stahnout/letak' },
    { from: '/download/rollup', to: '/stahnout/letak' },

    {
        from: '/spain', lang: {
            en: '/en/ES/business',
            es: '/es/ES/business',
            default: '/en/ES/business',
        },
    },
    {
        from: '/italy', lang: {
            en: '/en/IT/business',
            es: '/es/IT/business',
            default: '/en/IT/business',
        },
    },

    { from: '/contact-us', to: '/en/contact' },
    { from: '/byznys/kontakt', to: '/cs/kontakt' },

    { from: '/obchodni-podminky', to: '/cs/CZ/podminky' },
    { from: '/terms-and-conditions', to: '/en/terms' },
    {
        from: '/gdpr', lang: {
            en: '/en/CZ/terms',
            cs: '/cs/CZ/podminky',
            default: '/en/terms',
        },
    },

    {
        from: '/blog', lang: {
            en: '/en/CZ/blog',
            cs: '/cs/CZ/blog',
            default: '/en/CZ/blog',
        },
    },


    { from: '/spain/blog', to: '/blog' },
    { from: '/italy/blog', to: '/blog' },
    { from: '/italia/blog', to: '/blog' },
    {
        from: '/merchant-advance',
        to: '/business'
    },

    {
        // old - cz
        from: '/CZ/ocellot',
        to: '/pokladna',
    },
    {
        // old - cz
        from: '/CZ/ocellot/sign-up',
        to: '/pokladna/registrace',
    },
    {
        // old - sk
        from: '/CZ/ocellot/registracia',
        to: '/pokladna/registracia',
    },
    {
        // old - es
        from: '/CZ/ocellot/inscripcion',
        to: '/pos/sign-up',
    },
    {
        // old - it
        from: '/CZ/ocellot/iscrizione',
        to: '/pos/sign-up',
    },
];

fromToHP.forEach(itemFrom => {
    permanentRedirects.push({ from: itemFrom, to: '/' });
});

fromToPersonal.forEach(itemFrom => {
    permanentRedirects.push({ from: itemFrom, to: '/personal' });
});

const getPermanentRedirect = (url, lang) => {
    let redirectTo = null;
    for (let redirect of permanentRedirects) {
        const langs = redirect.lang;
        if (redirect.from === url && langs) {
            if (langs[lang]) {
                redirectTo = langs[lang];
                break;
            } else {
                redirectTo = langs.default;
                break;
            }
        } else if (redirect.from === url) {
            redirectTo = redirect.to;
            break;
        }
    }


    const regex = url.match(/blog\/(?<slug>[a-z-0-9]+)/);

    if (
        regex &&
        regex.groups &&
        regex.groups.slug &&
        redirectTo == null &&
        !url.includes('ES') && !url.includes('IT') && !url.includes('CZ')
    ) {
        const groups = regex.groups;
        redirectTo = '/' + lang + '/' + (languageToCountryCode[lang]?.toUpperCase() || 'CZ') + '/blog/' + groups.slug;
    }

    return redirectTo;
};


module.exports = getPermanentRedirect;
module.exports.countryURLWhitelist = countryURLWhitelist;
