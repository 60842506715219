import {Country} from "~services/i18n/country";
import {Currency} from "~services/i18n/currency";

type DataStructure = {
    [key in Country]: Currency;
}

export const currencyByCountry: DataStructure = {
    [Country.CZ]: Currency.CZK,
    [Country.SK]: Currency.EUR,
    [Country.ES]: Currency.EUR,
    [Country.IT]: Currency.EUR,
}

export function getCurrencyByCountry(country: Country): Currency {
    return currencyByCountry[country];
}
