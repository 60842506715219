const supportedCountries = ['cz', 'es', 'it', 'sk', 'pe'];
const countriesNames = {
    cz: 'Česko',
    es: 'España',
    it: 'Italia',
    sk: 'Slovakia',
    pe: 'Peru',
};
const defaultCountry = 'cz';

module.exports.countriesNames = countriesNames;
module.exports.supportedCountries = supportedCountries;
module.exports.defaultCountry = defaultCountry;
