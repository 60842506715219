const routing = require('./routing');

function getRouteFromAlias(alias) {
    let desiredRoute = null;
    routing.routes.forEach(route => {
        Object.keys(route.aliases).forEach(lang => {
            if (route.aliases[lang] === alias) {
                desiredRoute = route;
            }
        });
    });

    return desiredRoute;
}

function getAliasFromPath(path, lang) {
    let desiredRoute = routing.routes.find(route => route.id === path);
    if (!desiredRoute) return null;
    return desiredRoute.aliases[lang];
}

function matchActiveRoute(publicPath, internalPath) {
    let result = false;
    routing.routes.forEach(route => {
        if (route.template === publicPath && route.id === internalPath) {
            result = true;
        }
    });

    return result;
}

const getPath = (path, country) => {
    if (path === undefined) {
        return '/';
    }
    return typeof path === 'string' ? path : path[country];
};

const languageToCountryCode = {
    cs: 'cz',
    es: 'es',
    it: 'it',
    sk: 'sk',
};

const setCacheHeaders = (res, time) => {
    res.setHeader('Cache-Control', `public,max-age=${time}`);
}


module.exports.getRouteFromAlias = getRouteFromAlias;
module.exports.matchActiveRoute = matchActiveRoute;
module.exports.getPath = getPath;
module.exports.setCacheHeaders = setCacheHeaders;
module.exports.languageToCountryCode = languageToCountryCode;
module.exports.getAliasFromPath = getAliasFromPath;
