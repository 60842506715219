import React, { useState, useEffect } from 'react';
import { I18nProvider } from '@lingui/react';
import axios from 'axios';
import catalogCs from '~/locales/cs/messages';
import catalogSk from '~/locales/sk/messages';
import catalogEn from '~/locales/en/messages';
import catalogEs from '~/locales/es/messages';
import catalogIt from '~/locales/it/messages';
import catalogDe from '~/locales/de/messages';
import catalogFr from '~/locales/fr/messages';
import catalogPt from '~/locales/pt/messages';
import { supportedLangs, defaultLanguage } from './langUtils';
import { supportedCountries, defaultCountry } from './countryUtils';
import { countryURLWhitelist } from '../../../server/permanentRedirects';
import {Currency} from "~services/i18n/currency.ts";
import {getCurrencyByCountry} from "~services/i18n/currency-by-country";

//
// @todo: load catalogs items by added locales dynamically
//
const catalogs = {
    cs: catalogCs,
    sk: catalogSk,
    en: catalogEn,
    es: catalogEs,
    it: catalogIt,
    de: catalogDe,
    fr: catalogFr,
    pt: catalogPt
};

// todo: rewrite to typescript function + tests
// Util function to decide language based on browser preferences
const decideLangFromPref = req => {
    const locale = require('locale');

    const sampleLangs = new locale.Locales(supportedLangs, defaultLanguage);
    const prefLangs = new locale.Locales(req.headers['accept-language']);
    return prefLangs.best(sampleLangs).toString();
};

const LangContext = React.createContext({
    state: {
        lang: defaultLanguage,
        country: defaultCountry,
        currency: Currency.CZK,
    },
    actions: {
        setLang: () => null,
        setCountry: () => null,
    },
});

const LangProvider = props => {
    const [lang, setLang] = useState(props.clientLang);
    const [country, setCountry] = useState(props.clientCountry);
    const [currency, setCurrency] = useState(Currency.CZK);

    /** define currency by selected country */
    useEffect(() => {
        const mappedCurrency = getCurrencyByCountry(country);
        setCurrency(mappedCurrency);
    }, [country])

    /** setup cookies for SSR */
    useEffect(() => {
        document.cookie = 'country=' + country + ';path=/';
    }, [country]);

    return (
        <I18nProvider language={lang} catalogs={catalogs}>
            <LangContext.Provider
                value={{
                    state: {
                        lang,
                        country,
                        currency,
                    },
                    actions: {
                        setLang,
                        setCountry,
                        setCurrency,
                    },
                }}
            >
                {props.children}
            </LangContext.Provider>
        </I18nProvider>
    );
};

const getCountry = async () => {
    // do not run for SSR
    if (typeof window === "undefined") {
        return defaultCountry;
    }
    try {
        const resp = await axios.get('https://freegeoip.app/json/');
        return resp.data.country_code || defaultCountry;
    } catch (err) {
        console.error("getCountry - GeoIP problem", err);
    }
};

/**
 * todo: documentation!
 * todo: load it dynamically from supported langs!
 */
const parseCountry = countryCode => {
    if ('FR'.includes(countryCode?.toUpperCase()))
    {
        return 'fr';
    }
    if ('DE'.includes(countryCode?.toUpperCase()))
    {
        return 'de';
    }
    if ('PT'.includes(countryCode?.toUpperCase()))
    {
        return 'pt';
    }
    if ('SK'.includes(countryCode?.toUpperCase()))
    {
        return 'sk';
    }
    if (['CZ', 'PL', 'HU'].includes(countryCode?.toUpperCase()))
    {
        return 'cz';
    }
    else if (['IT', 'VA'].includes(countryCode?.toUpperCase()))
    {
        return 'it';
    }
    else if (['ES'].includes(countryCode?.toUpperCase()))
    {
        return 'es';
    }
    else
    {
        return defaultCountry;
    }
};

LangProvider.getInitialProps = async ({ req, res, asPath }) => {
    //Decides Country
    let defaultCountryFromIP = await getCountry();
    let clientCountry = parseCountry(defaultCountryFromIP);

    //Rewrite country when URL contains URL with country ID
    let countryFromUrl = asPath
        .substr(3, 4)
        .substr(1, 2)
        .toLowerCase();

    if (req) {
        if (req.headers.cookie) {
            if (
                req.cookies.country &&
                supportedCountries.includes(req.cookies.country)
            ) {
                clientCountry = req.cookies.country;
            }
        }
    }

    if (supportedCountries.includes(countryFromUrl)) {
        clientCountry = countryFromUrl;
    }

    // @todo: complicated - you have to add language every time, load it dynamically from supported langs!
    // Decides a language
    if (
        req &&
        !req.url.startsWith('/en') &&
        !req.url.startsWith('/cs') &&
        !req.url.startsWith('/sk') &&
        !req.url.startsWith('/es') &&
        !req.url.startsWith('/it') &&
        !req.url.startsWith('/fr') &&
        !req.url.startsWith('/de') &&
        !req.url.startsWith('/pt') 
    ) {
        let lang;
        if (req.headers.cookie) {
            if (req.cookies.lang && supportedLangs.includes(req.cookies.lang)) {
                lang = req.cookies.lang;
            } else {
                lang = decideLangFromPref(req);
            }
        } else {
            lang = decideLangFromPref(req);
        }

        // Redirects
        if (!res.headersSent) {
            const reqUrl = req.url === '/' ? '' : req.url;
            if (countryURLWhitelist.includes(reqUrl)) {
                res.writeHead(302, {
                    Location:
                        '/' + lang
                        + reqUrl,
                });
            } else {
                res.writeHead(302, {
                    Location:
                        '/' + lang + '/' + clientCountry.toUpperCase() + reqUrl,
                });
            }

            res.end();
        }
        return;
    }

    let clientLang = asPath.substr(1, 2);
    if (!supportedLangs.includes(clientLang)) {
        clientLang = defaultLanguage;
    }

    return { clientLang, clientCountry };
};

export { LangContext };
export default LangProvider;
