//
// all settings about lingui are in package.json
//

//
// @todo: load catalogs items by added locales dynamically
//
const supportedLangs = ['cs', 'sk', 'en', 'es', 'it', 'fr', 'de', 'pt'];

function languageList(localePreview) {
    return supportedLangs.filter(i => !localePreview.includes(i));
}

const languageNames = {
    cs: 'Čeština',
    sk: 'Slovenčina',
    en: 'English',
    es: 'Español',
    it: 'Italiano',
    fr: 'Français ',
    de: 'Deutsch',
    pt: 'Português',
};
const languageNamesShort = {
    cs: 'CZ',
    sk: 'SK',
    en: 'EN',
    es: 'ES',
    it: 'IT',
    fr: 'FR',
    de: 'DE',
    pt: 'PT',
};
const defaultLanguage = 'en';

const countryCodes = {
    spain: 'es',
    italy: 'it',
    romania: 'ro',
    czechia: 'cs',
    slovakia: 'sk',
    france: 'fr',
    germany: 'de',
    portugal: 'pt',
};

const langCodes = {
    es: 'es',
    it: 'it',
    cs: 'cs',
    sk: 'sk',
    en: 'cs',
    fr: 'fr',
    de: 'de',
    pt: 'pt'
}


const countryCodesBlog = {
    czechia: 'CZ',
    slovakia: 'SK',
    spain: 'ES',
    italy: 'IT',
    france: 'FR',
    germany: 'DE',
    portugal: 'PT',
};



const articleWithLocales = {
    en: null,
    cs: null,
    es: null,
    it: null,
    fr: null,
    de: null,
    pt: null
};

const getSuppLangsReFragment = langs => {
    const allLangs = langs.reduce((acc, e, i) => {
        if (i + 1 === langs.length) {
            return acc + e;
        } else {
            return acc + e + '|';
        }
    }, '');

    return '(' + allLangs + ')';
};

module.exports.languageNames = languageNames;
module.exports.languageNamesShort = languageNamesShort;
module.exports.supportedLangs = supportedLangs;
module.exports.defaultLanguage = defaultLanguage;
module.exports.getSuppLangsReFragment = getSuppLangsReFragment;
module.exports.countryCodes = countryCodes;
module.exports.articleWithLocales = articleWithLocales;
module.exports.countryCodesBlog = countryCodesBlog;
module.exports.langCodes = langCodes;
module.exports.languageList = languageList;
